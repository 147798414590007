var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showInput
    ? _c(
        "div",
        {
          staticClass: "input-group flex-nowrap",
          attrs: { "data-model": "vatNumber" },
        },
        [
          _vm.isEU
            ? _c(
                "div",
                { staticClass: "input-unit w-auto input-group-prepend" },
                [
                  _vm.vatCodes.length === 1
                    ? _c(
                        "span",
                        {
                          staticClass: "input-group-text h-100 border-0",
                          attrs: { id: "basic-addon1" },
                        },
                        [_vm._v(_vm._s(_vm.vatCodes[0]))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.vatCodes.length > 1
                    ? _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.vatPrefix,
                              expression: "vatPrefix",
                            },
                          ],
                          staticClass: "custom-select",
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.vatPrefix = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                          },
                        },
                        _vm._l(_vm.vatCodes, function (vatCode, index) {
                          return _c(
                            "option",
                            { key: index, domProps: { value: vatCode } },
                            [_vm._v(_vm._s(vatCode))]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate:text",
                  value: _vm.isRequired,
                  expression: "isRequired",
                  arg: "text",
                },
              ],
              staticClass: "input-unit flex-fill w-auto",
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.vatNumber,
                    expression: "vatNumber",
                    modifiers: { trim: true },
                  },
                ],
                attrs: {
                  "aria-describedby": "basic-addon1",
                  type: "text",
                  name: "vatNumber",
                  id: "txtVatNumber" + _vm._uid,
                  "data-autofocus": "",
                  "data-testing": "vat-id",
                },
                domProps: { value: _vm.vatNumber },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.vatNumber = $event.target.value.trim()
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "txtVatNumber" + _vm._uid } }, [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm.transformTranslation(
                        "Ceres::Template.addressVatNumber",
                        "de",
                        "billing_address.vatNumber"
                      )
                    ) +
                    "\n        "
                ),
              ]),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }