var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "d-flex mb-2" }, [
    _c("span", { staticClass: "text-muted" }, [
      _vm._v(_vm._s(_vm.quantity) + "x"),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "image-container mx-1" },
      [
        _vm.itemImage
          ? _c("lazy-img", {
              attrs: {
                "picture-class": "d-block mw-100 mh-100",
                "image-url": _vm.itemImage,
                alt: _vm.altText,
                title: _vm.itemName,
              },
            })
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      [
        _c(
          "a",
          {
            staticClass:
              "item-name text-primary text-appearance font-weight-bold text-break",
            attrs: { href: _vm._f("itemURL")(_vm.variation) },
          },
          [_vm._v("\n            " + _vm._s(_vm.itemName) + "\n        ")]
        ),
        _vm._v(" "),
        _vm._l(_vm.variation.attributes, function (attribute) {
          return _c("div", [
            _c("strong", [
              _vm._v(_vm._s(attribute.attribute.names.name) + ": "),
            ]),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(attribute.value.names.name))]),
          ])
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "text-muted" },
          [
            _vm._l(_vm.variation.variationProperties, function (propertyGroup) {
              return _vm._l(propertyGroup.properties, function (property) {
                return _c("div", [
                  propertyGroup.name
                    ? _c("strong", [_vm._v(_vm._s(propertyGroup.name) + ": ")])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(property.names.name))]),
                  _vm._v(" "),
                  property.cast == "file"
                    ? _c("span", [
                        _c("a", {
                          attrs: {
                            href: _vm._f("propertyFileUrl")(
                              property.values.value
                            ),
                            target: "_blank",
                          },
                          domProps: {
                            innerHTML: _vm._s(property.values.value),
                          },
                        }),
                      ])
                    : _c("span", {
                        domProps: { innerHTML: _vm._s(property.values.value) },
                      }),
                ])
              })
            }),
          ],
          2
        ),
        _vm._v(" "),
        _c("order-property-value-list", {
          attrs: { "basket-item": _vm.mappedBasketItem },
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }