var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pb-3" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 col-sm-6" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-primary btn-appearance btn-block coupon-edit",
            attrs: {
              type: "button",
              "data-toggle": "modal",
              "data-target": "#edit-coupon-overlay-" + _vm._uid,
            },
          },
          [
            _c("span", [
              _vm._v(_vm._s(_vm.$translate("Ceres::Template.couponEdit"))),
            ]),
            _vm._v(" "),
            _c("i", {
              staticClass: "fa fa-gift default-float",
              attrs: { "aria-hidden": "true" },
            }),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-sm-6" }, [
        !_vm.isFinalized
          ? _c(
              "button",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: !_vm.isPaid,
                    expression: "!isPaid",
                  },
                ],
                staticClass:
                  "btn btn-primary btn-appearance btn-block coupon-create",
                class: { disabled: _vm.isLoading || !_vm.isPaid },
                attrs: {
                  type: "button",
                  "data-placement": "top",
                  title: _vm.$translate("Ceres::Template.couponNotPaid"),
                },
                on: {
                  click: function ($event) {
                    return _vm.openConfirmModal()
                  },
                },
              },
              [
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.$translate("Ceres::Template.couponFinalize"))
                  ),
                ]),
                _vm._v(" "),
                _c("i", {
                  staticClass: "fa fa-check default-float",
                  attrs: { "aria-hidden": "true" },
                }),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.isFinalized && _vm.isPaid
          ? _c(
              "a",
              {
                staticClass:
                  "btn btn-primary btn-appearance btn-block coupon-download",
                attrs: {
                  href: _vm.pdfLink,
                  target: "_blank",
                  title: _vm.$translate("Ceres::Template.couponDownload"),
                },
              },
              [
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.$translate("Ceres::Template.couponDownload"))
                  ),
                ]),
                _vm._v(" "),
                _c("i", {
                  staticClass: "fa fa-download default-float",
                  attrs: { "aria-hidden": "true" },
                }),
              ]
            )
          : _vm._e(),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "form",
      {
        attrs: { method: "post" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submit()
          },
        },
      },
      [
        _c(
          "div",
          {
            ref: "editCouponOverlay",
            staticClass: "modal fade",
            attrs: {
              id: "edit-coupon-overlay-" + _vm._uid,
              tabindex: "-1",
              role: "dialog",
            },
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "modal-dialog modal-lg mx-auto modal-dialog-scrollable",
                attrs: { role: "document" },
              },
              [
                _c("div", { staticClass: "modal-content" }, [
                  _c("div", { staticClass: "modal-header" }, [
                    _c("div", { staticClass: "modal-title h4" }, [
                      _vm._v(
                        _vm._s(_vm.$translate("Ceres::Template.couponEdit"))
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "close",
                        attrs: {
                          type: "button",
                          "data-dismiss": "modal",
                          "aria-label": _vm.$translate(
                            "Ceres::Template.closeIcon"
                          ),
                        },
                        on: {
                          click: function ($event) {
                            return _vm.closeEditModal()
                          },
                        },
                      },
                      [
                        _c("span", { attrs: { "aria-hidden": "true" } }, [
                          _vm._v("×"),
                        ]),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "modal-body overflow-x-hidden modal-multi-row",
                    },
                    [
                      _vm._l(_vm.couponData, function (coupon) {
                        return [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-12 h5" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$translate("Ceres::Template.couponLabel")
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-12 col-sm-6" }, [
                              _c("div", { staticClass: "input-unit" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: _vm.isFinalized,
                                      expression: "isFinalized",
                                    },
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: coupon.sender,
                                      expression: "coupon.sender",
                                    },
                                  ],
                                  class: {
                                    disabled: _vm.isLoading || _vm.isFinalized,
                                  },
                                  attrs: {
                                    readonly: _vm.isFinalized,
                                    "data-placement": "top",
                                    title: _vm.$translate(
                                      "Ceres::Template.couponAlreadyFinalized"
                                    ),
                                    type: "text",
                                    name: "sender",
                                    autocomplete: "off",
                                    "data-validate": "text",
                                    "data-autofocus": "",
                                  },
                                  domProps: { value: coupon.sender },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        coupon,
                                        "sender",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("label", { attrs: { for: "sender" } }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$translate(
                                        "Ceres::Template.couponSender"
                                      )
                                    ) + "*"
                                  ),
                                ]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-12 col-sm-6" }, [
                              _c("div", { staticClass: "input-unit" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: _vm.isFinalized,
                                      expression: "isFinalized",
                                    },
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: coupon.recipient,
                                      expression: "coupon.recipient",
                                    },
                                  ],
                                  class: {
                                    disabled: _vm.isLoading || _vm.isFinalized,
                                  },
                                  attrs: {
                                    readonly: _vm.isFinalized,
                                    "data-placement": "top",
                                    title: _vm.$translate(
                                      "Ceres::Template.couponAlreadyFinalized"
                                    ),
                                    type: "text",
                                    name: "recipient",
                                    autocomplete: "off",
                                    "data-validate": "text",
                                  },
                                  domProps: { value: coupon.recipient },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        coupon,
                                        "recipient",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("label", { attrs: { for: "recipient" } }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$translate(
                                        "Ceres::Template.couponRecipient"
                                      )
                                    ) + "*"
                                  ),
                                ]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-12" }, [
                              _c("div", { staticClass: "input-unit" }, [
                                _c("textarea", {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: _vm.isFinalized,
                                      expression: "isFinalized",
                                    },
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: coupon.content,
                                      expression: "coupon.content",
                                    },
                                  ],
                                  class: {
                                    disabled: _vm.isLoading || _vm.isFinalized,
                                  },
                                  attrs: {
                                    readonly: _vm.isFinalized,
                                    "data-placement": "top",
                                    title: _vm.$translate(
                                      "Ceres::Template.couponAlreadyFinalized"
                                    ),
                                    name: "content",
                                    rows: "3",
                                    autocomplete: "off",
                                  },
                                  domProps: { value: coupon.content },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        coupon,
                                        "content",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("label", { attrs: { for: "content" } }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$translate(
                                        "Ceres::Template.couponContent"
                                      )
                                    )
                                  ),
                                ]),
                              ]),
                            ]),
                          ]),
                        ]
                      }),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "modal-footer" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-danger",
                        attrs: {
                          type: "button",
                          disabled: _vm.isLoading,
                          "data-dismiss": "modal",
                          "aria-label": _vm.$translate(
                            "Ceres::Template.closeIcon"
                          ),
                        },
                        on: {
                          click: function ($event) {
                            return _vm.closeEditModal()
                          },
                        },
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$translate("Ceres::Template.couponCancel")
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("i", {
                          staticClass: "fa fa-times default-float",
                          attrs: { "aria-hidden": "true" },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.isFinalized,
                            expression: "isFinalized",
                          },
                        ],
                        staticClass: "btn btn-primary",
                        class: { disabled: _vm.isLoading || _vm.isFinalized },
                        attrs: {
                          type: "submit",
                          "data-placement": "top",
                          title: _vm.$translate(
                            "Ceres::Template.couponAlreadyFinalized"
                          ),
                        },
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$translate("Ceres::Template.couponSave"))
                          ),
                        ]),
                        _vm._v(" "),
                        _c("icon", {
                          staticClass: "default-float",
                          attrs: { icon: "check", loading: _vm.isLoading },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]
            ),
          ]
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        ref: "confirmFinalizationOverlay",
        staticClass: "modal fade",
        attrs: {
          id: "confirm-finalization-overlay",
          tabindex: "-1",
          role: "dialog",
        },
      },
      [
        _c(
          "div",
          { staticClass: "modal-dialog", attrs: { role: "document" } },
          [
            _c("div", { staticClass: "modal-content" }, [
              _c("div", { staticClass: "modal-header" }, [
                _c("div", { staticClass: "modal-title h4" }, [
                  _vm._v(
                    _vm._s(_vm.$translate("Ceres::Template.couponFinalize"))
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "close",
                    attrs: {
                      type: "button",
                      "data-dismiss": "modal",
                      "aria-label": _vm.$translate("Ceres::Template.closeIcon"),
                    },
                    on: {
                      click: function ($event) {
                        return _vm.closeConfirmModal()
                      },
                    },
                  },
                  [
                    _c("span", { attrs: { "aria-hidden": "true" } }, [
                      _vm._v("×"),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body" }, [
                _c("div", { staticClass: "my-2" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$translate("Ceres::Template.couponFinalizeConfirm")
                    )
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-danger",
                    attrs: {
                      type: "button",
                      disabled: _vm.isFinalizing,
                      "data-dismiss": "modal",
                      "aria-label": _vm.$translate("Ceres::Template.closeIcon"),
                    },
                    on: {
                      click: function ($event) {
                        return _vm.closeConfirmModal()
                      },
                    },
                  },
                  [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$translate(
                            "Ceres::Template.couponFinalizeConfirmNo"
                          )
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("i", {
                      staticClass: "fa fa-times default-float",
                      attrs: { "aria-hidden": "true" },
                    }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    class: { disabled: _vm.isFinalizing },
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.finalize()
                      },
                    },
                  },
                  [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$translate(
                            "Ceres::Template.couponFinalizeConfirmYes"
                          )
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("icon", {
                      staticClass: "default-float",
                      attrs: { icon: "check", loading: _vm.isFinalizing },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }