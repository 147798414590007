var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.shownProperties && _vm.shownProperties.length
    ? _c("div", { staticClass: "small" }, [
        _c("div", { staticClass: "font-weight-bold my-1" }, [
          _vm._v(
            _vm._s(_vm.$translate("Ceres::Template.basketAdditionalCosts")) +
              ":"
          ),
        ]),
        _vm._v(" "),
        _c(
          "ul",
          {
            staticClass: "ml-1 pl-3",
            attrs: { "data-testing": "order-property-list" },
          },
          _vm._l(_vm.shownProperties, function (property) {
            return _c("order-property-value-list-item", {
              key: property.propertyId,
              attrs: { property: property },
            })
          }),
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }