var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.$ceres.isShopBuilder || _vm.matchingBasketItems.length
    ? _c(
        "div",
        { staticClass: "form-check", class: { error: _vm.showError } },
        [
          _c("input", {
            staticClass: "form-check-input",
            attrs: {
              type: "checkbox",
              id: "basket-item-consent" + _vm._uid,
              "data-testing": "basket-item-consent-check",
            },
            on: {
              change: function ($event) {
                _vm.value = $event.target.checked
              },
            },
          }),
          _vm._v(" "),
          _c(
            "label",
            {
              staticClass: "form-check-label",
              attrs: { for: "basket-item-consent" + _vm._uid },
            },
            [
              _vm.$ceres.isShopBuilder
                ? _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.$translate(
                          "Ceres::Template.checkoutBasketItemConsentPlaceholder"
                        )
                      )
                    ),
                  ])
                : _c("span", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$translate(
                          "Ceres::Template.checkoutBasketItemConsent",
                          { items: _vm.matchingItemNames }
                        )
                      ),
                    },
                  }),
              _c("sup", [_vm._v("*")]),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }