var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.setComponents.length > 0
    ? _c(
        "div",
        { staticClass: "set-data small" },
        [
          _c("div", { staticClass: "mb-2" }, [
            _c("strong", [
              _vm._v(_vm._s(_vm.$translate("Ceres::Template.itemSetContent"))),
            ]),
          ]),
          _vm._v(" "),
          _vm._l(_vm.setComponents, function (setComponent) {
            return [
              _c("set-component-item", {
                attrs: {
                  variation: _vm.variations[setComponent.itemVariationId],
                  quantity: setComponent.quantity,
                  "order-properties": setComponent.orderProperties,
                },
              }),
            ]
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }