var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("li", [
    _c("span", { staticClass: "d-block" }, [
      _c(
        "strong",
        { class: { colon: _vm.showColon } },
        [
          _vm._v(
            "\n            " +
              _vm._s(_vm.property.property.names.name) +
              " \n            "
          ),
          _vm.surcharge > 0
            ? [
                _vm.isAdditionalCost || _vm.isTaxless
                  ? [
                      _vm._v(
                        "\n                    (" +
                          _vm._s(
                            _vm.$translate("Ceres::Template.basketPlusAbbr")
                          ) +
                          " " +
                          _vm._s(_vm._f("currency")(_vm.surcharge)) +
                          ")\n                "
                      ),
                    ]
                  : [
                      _vm._v(
                        "\n                    (" +
                          _vm._s(
                            _vm.$translate("Ceres::Template.basketIncludeAbbr")
                          ) +
                          " " +
                          _vm._s(_vm._f("currency")(_vm.surcharge)) +
                          ")\n                "
                      ),
                    ],
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c("span", [
        _vm.property.property.valueType === "file"
          ? _c("span", [
              _c(
                "a",
                {
                  attrs: {
                    href: _vm._f("fileUploadPath")(_vm.property.property.value),
                    target: "_blank",
                  },
                },
                [
                  _c("i", {
                    staticClass: "fa fa-external-link",
                    attrs: { "aria-hidden": "true" },
                  }),
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm._f("fileName")(_vm.property.property.value)) +
                      "\n                "
                  ),
                ]
              ),
            ])
          : _vm.property.property.valueType !== "empty"
          ? _c("span", [_vm._v(_vm._s(_vm.property.property.value))])
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }